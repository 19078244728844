<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-button(v-permission="['system:user:create']" type="primary" @click="onCreate" icon="el-icon-edit").filter-item.ml-5 新建用户

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="left" label="登录名" prop="username" sortable)
            el-table-column(align="left" label="用户名" prop="name" sortable)
            el-table-column(align="right" label="角色" prop="roles" sortable)
                template(slot-scope="scope")
                    el-tag(v-for="role in scope.row.roles" :key="role") {{formatRole(role)}}
            el-table-column(align="right" label="创建时间" prop="creation_date" sortable)
                template(slot-scope="scope")
                    span {{scope.row.creation_date | formatDateAndTime}}
            el-table-column(align="right" label="帐号状态" prop="status" sortable)
                template(slot-scope="scope") {{scope.row.status?'生效':'失效'}}
            el-table-column(align="right" label="上次成功登录时间" prop="last_login_date" sortable)
                template(slot-scope="scope") {{scope.row.last_login_date|formatDateAndTime}}
            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-button(v-permission="['system:user:update']" type="primary" size="mini" @click="onUpdate(scope.row)") 修改
                    el-button(v-permission="['system:user:delete']" type="danger" size="mini" @click="onDelete(scope.row)") 删除

        //- 添加或修改对话框
        el-dialog(:title="textMap[dialogStatus]" :visible.sync="dialogFormVisible")
            el-form(ref="dataForm" :rules="rules" :model="dataForm" status-icon label-position="left" label-width="100px" style="width: 400px; margin-left:50px;")
                el-form-item(label="登录名" prop="username")
                    el-input(:disabled="dialogStatus === 'update'" v-model="dataForm.username")
                el-form-item(label="用户名" prop="name")
                    el-input(v-model="dataForm.name" )
                el-form-item(label="密码" prop="password")
                    el-input(v-model="dataForm.password" show-password)
                el-form-item(label="授权角色" prop="roles")
                    el-select(v-model="dataForm.roles" multiple placeholder="请选择")
                        el-option(v-for="item in roleOptions" :key="item.value" :label="item.label" :value="item.value")
                el-form-item(label="是否生效")
                    el-checkbox(v-model="dataForm.status")
            div(slot="footer").dialog-footer
                el-button(@click="dialogFormVisible = false") 取消
                el-button(v-if="dialogStatus=='create'" type="primary" @click="onCreateData") 确定
                el-button(v-else type="primary" @click="onUpdateData") 确定

</template>

<script>
import {
  getUserList,
  getRoleOptions,
  createUser,
  updateUser,
  deleteUser
} from "@/api/system";
import _ from "lodash";

import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

import DialogFormMixin from "@/mixins/dialog-form-parts";

export default {
  name: "UserList",
  mixins: [DialogFormMixin],
  components: { Pagination },
  data() {
    return {
      roleOptions: [],
      list: null,
      total: 0,
      listLoading: true,
      rules: {
        username: [
          { required: true, message: "登录名不能为空", trigger: "blur" }
        ],
        roles: [{ required: true, message: "授权角色", trigger: "blur" }],
        name: [
          { required: true, message: "管理员名称不能为空", trigger: "blur" }
        ],
        password: [{ required: true, message: "密码不能为空", trigger: "blur" }]
      }
    };
  },
  async created() {
    await this.getList();
    const resp = await getRoleOptions();
    this.roleOptions = resp.data.data;
  },
  methods: {
    formatRole(roleId) {
      for (let i = 0; i < this.roleOptions.length; i++) {
        if (roleId === this.roleOptions[i].value) {
          return this.roleOptions[i].label;
        }
      }
      return "";
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await getUserList(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    resetForm() {
      this.dataForm = {
        id: undefined,
        username: undefined,
        password: undefined,
        status: false,
        roles: []
      };
    },
    onCreateData() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const resp = await createUser(this.dataForm);
          this.list.unshift(resp.data.data);
          this.dialogFormVisible = false;
          this.$notify.success({
            title: "成功",
            message: "添加管理用户帐号成功"
          });
        }
      });
    },
    onCreate() {
      this.resetForm();
      this.dialogFormVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    onUpdate(row) {
      this.dataForm = Object.assign({}, row);
      this.dialogFormVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    onUpdateData() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const resp = await updateUser(this.dataForm);
          const _user = resp.data.data;
          const user = _.find(this.list, { username: _user.username });
          _.assign(user, _user);
          this.dialogFormVisible = false;
          this.$notify.success({
            title: "成功",
            message: "更新管理员成功"
          });
        }
      });
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将永久删除该管理员---" + row.username + "---, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        await deleteUser(row);

        // 提示操作成功
        this.$notify.success({
          title: "成功",
          message: "删除管理员成功"
        });
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      } catch (error) {
        // 因为按了提示框中取消就是有异常
        return false;
      }
    }
  }
};
</script>
